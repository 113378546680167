import React from "react"
import Header from "../components/header-details"
import DeveloperHeader from "../components/developer-header"
import DeveloperMall from "../components/developer-mall"
import SocialShare from '../components/socialshare';
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Footer from "../components/footer"
// do not remove this import, it loads the styles for the page
import Layout from "../components/layout";
import { checkPropTypes } from "prop-types"




export default class DevelopersPage extends React.Component {

  componentDidMount() {

  }

  

  render() {
      const data = this.props.data;

      return (
        <>
        <Helmet>
        </Helmet>
        
        <div className="full-width" id="developerPage">
          <Header />
          <DeveloperHeader developers={this.props.pageContext.developers} path={this.props.path}/>

          <div id="railL" className="cols">

          <div className="col2-3 malldetailspageR col-m-1">

            <SocialShare />
          </div>
          </div>


          <Footer title={''} />
        </div>
      
      </>
      ) 
      


  }
}





